import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-validify';

import { FormInput } from 'components';

const CopyEventForm = (props) => {
  return (
    <Form
      values={props.values}
      rules={{
        title: 'string|required',
      }}
    >
      <FormInput
        id="title"
        name="title"
        type="text"
        className="form-control"
        label={{ htmlFor: 'title', text: 'New Event Title' }}
      />
      <div className="form-group mt2">
        <button
          type="button"
          submit
          className="btn btn-primary"
          onClick={(values) => props.onFormSubmit(values)}
        >
          {props.submitButtonText || 'Submit'}
        </button>
      </div>
    </Form>
  );
};

CopyEventForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
  values: PropTypes.object,
};

export default CopyEventForm;
