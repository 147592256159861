import flatten from 'lodash/flatten';
import { filterDelimiter } from './helpers';

// This search enables:
//  a) "AND" operations between different search categories
//  b) "OR" operations within a repeated category
// This way, you can search for:
//  * All ISTs that are part of the "career path" strand
//  * All workshops or seminars that you were approved for

export const filterEvents = (events, searchTerms) => {
  const groupedTerms = groupTerms(searchTerms);
  return events.filter((event) => matching(event, groupedTerms));
};

const groupTerms = (searchTerms) => {
  // Combine terms that share the same type
  let groupedTerms = {};
  searchTerms.forEach((term) => {
    const splits = term.split(filterDelimiter);
    const type = splits[0];
    const value = splits[1];

    if (!groupedTerms[type]) {
      groupedTerms[type] = [];
    }

    groupedTerms[type].push(value);
  });
  return groupedTerms;
};

const matching = (event, groupedTerms) => {
  const foundTerm = Object.entries(groupedTerms).every((termArray) => {
    const type = termArray[0];
    const values = termArray[1];

    let found = false;
    switch (type) {
      case 'title':
        const lowercaseTitle = event.title.toLowerCase();
        found = values.some((value) => lowercaseTitle.includes(value.toLowerCase()));
        break;
      case 'day':
        const days = flatten(event.times.map((time) => time[1]));
        found = values.some((value) => days.includes(value));
        break;
      default:
        found = values.includes(event[type]);
    }

    return found;
  });
  return foundTerm;
};
