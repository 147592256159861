import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { eventExplorePropTypes } from 'helpers/proptypes';
import {
  eventTypeFormatter,
  strandTypeFormatter,
  titleFormatter,
  registrationFormatter,
  statusFormatter,
} from 'helpers/react-table-helpers';
import EventStatus from './components/EventStatus';
import EventCallouts from './components/EventCallouts';
import CopyEventAction from './CopyEventAction';

function generatePath(reverse, propId = 'pk', index = false) {
  return function(props) {
    let id = props[propId];
    if (!id) {
      return false;
    }
    if (index !== false) {
      id = id[index];
    }
    return window.URLS[reverse](id);
  };
}

const actionSections = [
  {
    text: 'Proposal',
    actions: [
      {
        text: 'View Proposal',
        icon: 'fa-file-text-o',
        path: generatePath('pd:event_proposal_detail'),
      },
      {
        text: 'Edit Proposal',
        icon: 'fa-pencil',
        path: generatePath('pd:event_proposal_update'),
      },
      {
        separator: true,
      },
      {
        text: 'Export RID Instructor Form',
        icon: 'fa-download',
        path: generatePath('pd:event_instructor_form_export'),
      },
      {
        text: 'Export RID Sponsor Form',
        icon: 'fa-download',
        path: generatePath('pd:event_sponsor_form_export'),
        disabled: (event) => !event.rid_activity_number_created,
        disabledTitle: 'Event has not been assigned an RID activity number',
      },
      {
        text: 'Export RID Facilitator Sponsor Form',
        icon: 'fa-download',
        path: (event) => generatePath('pd:event_sponsor_form_export')(event) + '?facilitator=true',
        hidden: (event) =>
          !event.facilitator_rid_activity_number_created && !event.facilitator_requesting_ceus,
        disabled: (event) => !event.facilitator_rid_activity_number_created,
        disabledTitle: 'Event has not been assigned an RID activity number for facilitator',
      },
      {
        text: 'Export Attendance Logs',
        icon: 'fa-download',
        path: (event) => generatePath('pd:attendance_log_export_pdf')(event),
        disabled: (event) => !event.has_attendance,
        disabledTitle: 'Event has no recorded attendance',
      },
      {
        text: 'Export Event PDF',
        icon: 'fa-download',
        path: (event) => event.pdf_url,
        disabled: (event) => !event.pdf_url || !event.pdf_url.length,
        disabledTitle: 'Event must be published to have a PDF generated.',
      },
    ],
  },
  {
    text: 'Event',
    actions: [
      {
        text: 'View Event',
        icon: 'fa-info-circle',
        path: generatePath('pd:event_detail'),
        router: true,
      },
      {
        text: 'Edit Event',
        icon: 'fa-pencil',
        path: generatePath('pd:event_update'),
      },
      {
        separator: true,
      },
      {
        text: 'Cancel a Meeting',
        icon: 'fa-calendar-times-o',
        path: generatePath('pd:meeting_cancelation_create'),
      },
      {
        text: 'Copy Event',
        icon: 'fa-clone',
        path: 'copy',
      },
      {
        text: 'Cancel Event',
        icon: 'fa-times',
        path: generatePath('pd:cancel_event'),
      },
      {
        text: 'Delete Event',
        icon: 'fa-trash',
        path: generatePath('pd:event_delete'),
      },
    ],
  },
  {
    text: 'Registration',
    actions: [
      {
        text: 'Registration List',
        icon: 'fa-users',
        path: generatePath('pd:event_registration_list'),
        router: true,
      },
      {
        text: 'Invitations',
        icon: 'fa-envelope',
        path: generatePath('pd:facilitator_invitations'),
        router: true,
        disabled: (event) => !event.private,
        disabledTitle: 'Only private events may have invitations',
      },
      {
        text: 'Attendance Tracker',
        icon: 'fa-list-alt',
        path: generatePath('pd:attendance_tracker'),
        router: true,
      },
      {
        text: 'Wrap Up',
        icon: 'fa-gift',
        path: generatePath('pd:facilitator_wrap_up_detail'),
        router: true,
      },
      {
        text: 'Progress Report',
        icon: 'fa-file-text-o',
        path: generatePath('pd:progress_report_detail'),
      },
      {
        text: 'Evaluation Results',
        icon: 'fa-bar-chart',
        path: generatePath('surveys:survey_facilitator_results_detail', 'surveys', 0),
        router: true,
      },
      {
        separator: true,
      },
      {
        text: 'Export CEU Submission Roster',
        icon: 'fa-download',
        path: generatePath('pd:ceu_submission_roster_export'),
      },
      {
        text: 'Export Contact List',
        icon: 'fa-download',
        path: generatePath('pd:contact_list_export'),
      },
    ],
  },
];

export const Actions = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        maxWidth: '800px',
      }}
    >
      {actionSections.map((section) => (
        <div key={section.text}>
          <strong style={{ marginBottom: '4px', display: 'block' }}>{section.text}</strong>
          <ul className="list-unstyled" style={{ lineHeight: '1.75' }}>
            {section.actions.map((action, index) => {
              if (action.hidden && action.hidden(props)) {
                return null;
              }
              if (action.separator) {
                return (
                  <li role="separator" className="divider" key={`separator-${index}`}>
                    <span className={`fa fa-fw icon-space-r`} />
                    &mdash;
                  </li>
                );
              }
              if (action.disabled && action.disabled(props)) {
                return (
                  <li key={action.text} className="disabled-link" title={action.disabledTitle}>
                    <span className={`fa fa-fw ${action.icon} icon-space-r`} />
                    {action.text}
                  </li>
                );
              }
              if (action.router) {
                const link = action.path(props);
                if (!link) {
                  return null;
                }
                return (
                  <li key={action.text}>
                    <Link to={link}>
                      <span className={`fa fa-fw ${action.icon} icon-space-r`} />
                      {action.text}
                    </Link>
                  </li>
                );
              }
              if (action.path === 'copy') {
                return (
                  <li key={action.text}>
                    <CopyEventAction eventId={props.pk} icon={action.icon} title={action.text} />
                  </li>
                );
              }
              return (
                <li key={action.text}>
                  <a
                    href={action.path(props)}
                    {...(action.icon === 'fa-download' ? { download: true } : {})}
                  >
                    <span className={`fa fa-fw ${action.icon} icon-space-r`} />
                    {action.text}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      ))}
    </div>
  );
};

Actions.propTypes = {
  pk: PropTypes.number.isRequired,
};

export default class EventsTable extends React.Component {
  static propTypes = {
    events: PropTypes.arrayOf(eventExplorePropTypes).isRequired,
  };

  render() {
    const columns = [
      {
        Header: 'Type',
        accessor: 'type',
        Cell: (row) => eventTypeFormatter({ type: row.value }),
        maxWidth: 70,
      },
      {
        Header: 'Strand',
        accessor: 'strand_type',
        Cell: (row) => strandTypeFormatter({ strand: row.value }),
        maxWidth: 70,
      },
      {
        Header: 'Event Title',
        id: 'title',
        accessor: (props) => titleFormatter(props),
      },
      {
        Header: 'Registration',
        id: 'registration',
        accessor: (props) => registrationFormatter(props),
        maxWidth: 220,
      },
      {
        Header: 'Pending',
        maxWidth: 90,
        id: 'pending_requests',
        accessor: (props) => <span className="flex-center">{props.pending_requests}</span>,
      },
      {
        Header: 'Seats Open',
        id: 'capacity',
        accessor: (props) => (
          <span className="flex-center">
            {props.seats_open} / {props.capacity}
          </span>
        ),
        maxWidth: 90,
      },
      {
        Header: 'Status',
        accessor: 'publication_status',
        Cell: (row) => statusFormatter({ status: row.value, canceled: row.original.canceled }),
        maxWidth: 90,
      },
    ];
    return (
      <ReactTable
        className="-striped"
        data={this.props.events}
        columns={columns}
        SubComponent={(state) => {
          const event = state.original;
          return (
            <div style={{ padding: '15px 25px 20px' }}>
              <div style={{ marginBottom: '12px' }}>
                <EventCallouts {...event} />
                <EventStatus firstDate={moment(event.first)} lastDate={moment(event.last)} />
              </div>
              <Actions {...event} />
            </div>
          );
        }}
      />
    );
  }
}
