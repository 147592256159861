import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';

import CopyEventForm from './CopyEventForm';

const CopyEventModal = ({ modalOpen, onModalClose, onFormSubmit, eventId, values }) => {
  return (
    <Modal show={modalOpen} onHide={onModalClose} modalPrefix="bootstrap-modal modal">
      <Modal.Header closeButton>
        <Modal.Title>Copy Event</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CopyEventForm onFormSubmit={onFormSubmit} eventId={eventId} values={values} />
      </Modal.Body>
    </Modal>
  );
};

CopyEventModal.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  eventId: PropTypes.number.isRequired,
  values: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
};

export default CopyEventModal;
