import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  filterAcademicSeason,
  requestEventsAdmin,
  syncAdminFilters,
} from 'store/actions/events/admin';
import { eventExplorePropTypes } from 'helpers/proptypes';
import { eventRadioOptionsPropTypes } from './proptypes';
import { notifyError, notifySuccess } from 'api';

import { ActionButtons, PageHeader } from 'components';
import { AcademicYearSelect } from 'components/shared';
import EventsBase from './EventsBase';
import EventsTable from './EventsTable';
import EventRadioGroup from './EventRadioGroup';

export class EventsAdmin extends React.Component {
  static propTypes = {
    events: PropTypes.arrayOf(eventExplorePropTypes).isRequired,
    filteredEvents: PropTypes.arrayOf(eventExplorePropTypes).isRequired,
    eventScope: PropTypes.string,
    academicSeason: PropTypes.string.isRequired,
    academicSeasons: eventRadioOptionsPropTypes.isRequired,
    isLoading: PropTypes.bool.isRequired,
    hasErrored: PropTypes.bool.isRequired,
    requestEventsAdmin: PropTypes.func.isRequired,
    searchTerms: PropTypes.arrayOf(PropTypes.string).isRequired,
    options: PropTypes.object,
    syncAdminFilters: PropTypes.func.isRequired,
    filterAcademicSeason: PropTypes.func.isRequired,
  };

  handleAcademicYearChange = (selectedValue) => {
    this.props.requestEventsAdmin(selectedValue);
  };

  handleSeasonChange = (season) => {
    this.props.filterAcademicSeason(season);
  };

  refresh = async () => {
    try {
      await this.props.requestEventsAdmin(this.props.eventScope, true);
      notifySuccess(`Refreshed events for ${this.props.eventScope}`);
    } catch (err) {
      notifyError(err, `Could not refresh events for ${this.props.eventScope}`);
    }
  };

  componentDidMount() {
    this.props.requestEventsAdmin(this.props.eventScope);
  }

  render() {
    const filters = ['day', 'type', 'strand_type', 'publication_status'];
    const adminButtons = [
      [window.URLS['pd:proposed_calendar_event_list'](), 'Manage Calendar Events', 'fa-calendar'],
      [window.URLS['pd:calendar_event_create'](), 'Add Calendar Event', 'fa-plus'],
      [window.URLS['pd:event_admin_attendance']('/'), 'Attendance & Progress', 'fa-users'],
      [window.URLS['pd:event_admin_ids'](), 'Event IDs List', 'fa-list-ul', true],
      [window.URLS['pd:event_create'](), 'Create Event', 'fa-plus'],
    ];
    return (
      <div className="col-xs-12">
        <div className="row">
          <div className="white-soft-box2 white-soft-box2--no-animate">
            <PageHeader.Basic title="Events Admin Dashboard" center />
            <div style={{ marginTop: '1em', marginBottom: '2.5em', textAlign: 'center' }}>
              <ActionButtons
                buttons={adminButtons}
                buttonClassName="btn-sm"
                leftOrRightSpacing="right"
              />
            </div>
            <button className="btn btn-lg btn-default pull-right" onClick={this.refresh}>
              <i className="fa fa-refresh" />
            </button>
            <div className="text-center mb2">
              <div className="flex-row flex-row--center">
                <div>
                  <label>Academic Year</label>
                  <div style={{ marginTop: '5px' }}>
                    <AcademicYearSelect
                      value={this.props.eventScope}
                      onChange={this.handleAcademicYearChange}
                      style={{ width: 200 }}
                    />
                  </div>
                </div>
                <span className="icon-space-l icon-space-r" />
                <div>
                  <label htmlFor="eventFilter">Academic Season</label>
                  <div>
                    <EventRadioGroup
                      selectedValue={this.props.academicSeason}
                      onChange={this.handleSeasonChange}
                      options={this.props.academicSeasons}
                    />
                  </div>
                </div>
              </div>
            </div>
            <EventsBase
              Outlet={EventsTable}
              filterClassName="mb2"
              filters={filters}
              events={this.props.events}
              isLoading={this.props.isLoading}
              hasErrored={this.props.hasErrored}
              filteredEvents={this.props.filteredEvents}
              options={this.props.options}
              searchTerms={this.props.searchTerms}
              syncFilters={this.props.syncAdminFilters}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    events: state.eventsAdmin.seasonalEvents,
    filteredEvents: state.eventsAdmin.filteredEvents,
    hasErrored: state.eventsAdmin.hasErrored,
    isLoading: state.eventsAdmin.isLoading,
    academicyears: state.eventsAdmin.academicyears,
    eventScope: state.eventsAdmin.eventScope,
    options: state.eventsAdmin.options,
    searchTerms: state.eventsAdmin.searchTerms,
    academicSeason: state.eventsAdmin.academicSeason,
    academicSeasons: state.eventsAdmin.academicSeasons,
  };
};

export default connect(
  mapStateToProps,
  {
    requestEventsAdmin,
    syncAdminFilters,
    filterAcademicSeason,
  }
)(EventsAdmin);
