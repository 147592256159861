import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { eventExplorePropTypes } from 'helpers/proptypes';
import { copyEventThunk } from 'store/actions/events/admin';
import CopyEventModal from './CopyEventModal';

class CopyEventAction extends React.Component {
  static propTypes = {
    copyEventThunk: PropTypes.func.isRequired,
    eventId: PropTypes.number.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    event: eventExplorePropTypes,
  };

  state = {
    modalOpen: false,
    values: { title: (this.props.event && this.props.event.title) || '' },
  };

  handleModalOpen = (e) => {
    e.preventDefault();
    this.setState({ modalOpen: true });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  handleCopyEventFormSubmit = (values) => {
    this.props.copyEventThunk(this.props.eventId, values);
    this.setState({ modalOpen: false });
  };

  render() {
    return (
      <div>
        <a onClick={this.handleModalOpen} href="">
          <span className={`fa ${this.props.icon} fa-fw icon-space-r`} />
          {this.props.title}
        </a>
        <CopyEventModal
          eventId={this.props.eventId}
          onFormSubmit={this.handleCopyEventFormSubmit}
          modalOpen={this.state.modalOpen}
          onModalClose={this.handleModalClose}
          values={this.state.values}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    event: state.eventsAdmin.events.find((event) => event.pk === props.eventId),
  };
};

export const CopyEventActionUnwrapped = CopyEventAction;

export default connect(
  mapStateToProps,
  { copyEventThunk }
)(CopyEventAction);
