import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Media from 'react-media';
import { RadioGroup, Radio } from 'react-radio-group';

import { eventRadioOptionsPropTypes } from './proptypes';

const EventRadioGroup = ({ isMobile, options, ...radioProps }) => {
  const specifiesPrimary = radioProps.selectedValue
    ? !!options.find((option) => option.key === radioProps.selectedValue)
    : !!options.find((option) => option.active === true);
  const mediaQueryClasses = classNames('', {
    'btn-group': !isMobile,
    'btn-group-vertical': isMobile,
  });
  return (
    <RadioGroup {...radioProps} name="eventFilter" className={mediaQueryClasses}>
      {options.map((option, index) => {
        let isPrimary = false;
        if (radioProps.selectedValue) {
          isPrimary = specifiesPrimary ? option.key === radioProps.selectedValue : false;
        } else {
          isPrimary = specifiesPrimary ? option.active === true : index === 0;
        }
        const className = classNames('btn', {
          'btn-default': !isPrimary,
          'btn-primary': isPrimary,
        });
        return (
          <label className={className} key={option.key}>
            <Radio value={option.key} className="events-radio-group__radio" />
            {option.title}
          </label>
        );
      })}
    </RadioGroup>
  );
};

EventRadioGroup.propTypes = {
  options: eventRadioOptionsPropTypes.isRequired,
  selectedValue: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
};

const MediaQueryWrapper = (props) => (
  <Media query="(min-width: 600px)">
    {(matches) =>
      matches ? (
        <EventRadioGroup {...props} isMobile={false} />
      ) : (
        <EventRadioGroup {...props} isMobile />
      )
    }
  </Media>
);

export default MediaQueryWrapper;
