import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import cx from 'classnames';

import { EventLabels, EventDictionary } from 'helpers/dictionaries';
import {
  eventTypePropType,
  eventStrandPropType,
  eventPublicationStatusPropType,
} from 'components/events/proptypes';

const { PUB_STATUS } = EventDictionary;

export const eventTypeFormatter = (props) => (
  <span className="flex-center">{EventLabels.EVENT_TYPES_ABBREVIATED[props.type]}</span>
);

eventTypeFormatter.propTypes = {
  type: eventTypePropType,
};

export const strandTypeFormatter = (props) => (
  <div className={`event-block--${props.strand} flex-center`}>
    <div className="event-td__strand-icon" />
  </div>
);

strandTypeFormatter.propTypes = {
  strand: eventStrandPropType,
};

export const titleFormatter = ({ pk, title }) => <Link to={`/events/${pk}`}>{title}</Link>;

titleFormatter.propTypes = {
  pk: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

// eslint-disable-next-line camelcase
export const registrationFormatter = ({ registration_start, registration_end }) => {
  const format = (value) => moment(value).format('MMM D, YYYY');
  return `${format(registration_start)} to ${format(registration_end)}`;
};

registrationFormatter.propTypes = {
  registration_start: PropTypes.string.isRequired,
  registration_end: PropTypes.string.isRequired,
};

export const statusFormatter = (props) => {
  if (props.canceled) {
    return (
      <div className="flex-center">
        <span className="label label-danger">Canceled</span>
      </div>
    );
  }
  if (props.status === PUB_STATUS.PUBLISHED) {
    return (
      <div className="flex-center">
        <span className="label label-success">Published</span>
      </div>
    );
  }
  return (
    <div className="flex-center">
      <span className="label label-default">Draft</span>
    </div>
  );
};

statusFormatter.propTypes = {
  status: eventPublicationStatusPropType.isRequired,
  canceled: PropTypes.bool.isRequired,
};

export const employeePhotoFormatter = ({ photo }) => (
  <img src={photo} alt="Employee Photo" width="32" height="40" />
);

employeePhotoFormatter.propTypes = {
  photo: PropTypes.string.isRequired,
};

export const yearFormatter = ({ year }) => {
  return <div className="flex-center">{year}</div>;
};

yearFormatter.propTypes = {
  year: PropTypes.string.isRequired,
};

export const seasonFormatter = ({ season }) => <div className="flex-center">{season}</div>;

seasonFormatter.propTypes = {
  season: PropTypes.oneOf(['Fall', 'Spring', 'Summer']),
};

export const workshopIdFormatter = ({
  key,
  ceus,
  workshopId,
  facilitatorRequestingCeus,
  onBlur,
  __html,
}) => {
  let danger = false;
  if (key === 'workshop_id') {
    danger = Boolean(ceus && ceus.length && !workshopId);
  }
  if (key === 'facilitator_workshop_id') {
    danger = Boolean(facilitatorRequestingCeus && !workshopId);
  }

  const classes = cx({ danger });
  return (
    <div
      className={classes}
      contentEditable
      suppressContentEditableWarning
      onBlur={onBlur}
      dangerouslySetInnerHTML={{ __html }}
    />
  );
};

workshopIdFormatter.propTypes = {
  key: PropTypes.string.is_required,
  ceus: PropTypes.number,
  workshopId: PropTypes.number.isRequired,
  facilitatorRequestingCeus: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  __html: PropTypes.string.isRequired,
};

export const ridActivityNumberFormatter = ({
  key,
  ceus,
  ridActivityNumber,
  facilitatorRequestingCeus,
  onBlur,
  __html,
}) => {
  let danger = false;
  if (key === 'rid_activity_number') {
    danger = Boolean(ceus && ceus.length && !ridActivityNumber);
  }
  if (key === 'facilitator_rid_activity_number') {
    danger = Boolean(facilitatorRequestingCeus && !ridActivityNumber);
  }
  const classes = cx({ danger });
  return (
    <div
      className={classes}
      contentEditable
      suppressContentEditableWarning
      onBlur={onBlur}
      dangerouslySetInnerHTML={{ __html }}
    />
  );
};

ridActivityNumberFormatter.propTypes = {
  key: PropTypes.string.is_required,
  ceus: PropTypes.number,
  ridActivityNumber: PropTypes.string.isRequired,
  facilitatorRequestingCeus: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  __html: PropTypes.string.isRequired,
};

export const facilitatorRequestingCeusFormatter = ({ facilitatorRequestingCeus }) => {
  const classes = cx('flex-center', {
    danger: facilitatorRequestingCeus,
  });
  return (
    <div className={classes}>
      {facilitatorRequestingCeus ? <i className="fa fa-check" /> : null}
    </div>
  );
};

facilitatorRequestingCeusFormatter.propTypes = {
  facilitatorRequestingCeus: PropTypes.bool.isRequired,
};
