import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import MultiSelect from 'react-bootstrap-multiselect';

import { eventExplorePropTypes } from 'helpers/proptypes';
import { EventLabels } from 'helpers/dictionaries';
import { Alert, Loading } from 'components';
import {
  filterReturnValue,
  filterDelimiter,
  filterButtonLabels,
  defaultFilterOptions,
  dictFilters,
} from './helpers';
import { filterEvents } from './filterEvents';

import EventSelectize from './EventSelectize';

export class EventsWrapper extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    hasErrored: PropTypes.bool,
    events: PropTypes.arrayOf(eventExplorePropTypes).isRequired,
    Outlet: PropTypes.func,
    EmptyOutlet: PropTypes.func,
  };

  sortEvents(events) {
    return events.sort((a, b) => (a.first > b.first ? 1 : -1));
  }

  render() {
    let { isLoading, hasErrored, events, EmptyOutlet, Outlet } = this.props;
    if (isLoading) {
      return <Loading />;
    } else if (hasErrored) {
      return (
        <Alert type="danger" icon="fa-exclamation-triangle">
          Something went wrong. Could not retrieve events.
        </Alert>
      );
    } else if (events.length < 1) {
      return EmptyOutlet ? (
        <EmptyOutlet />
      ) : (
        <Alert type="warning" icon="fa-exclamation-triangle">
          No events found.
        </Alert>
      );
    } else {
      return <Outlet events={this.sortEvents(events)} />;
    }
  }
}

export default class EventsBase extends Component {
  static propTypes = {
    events: PropTypes.arrayOf(eventExplorePropTypes).isRequired,
    isLoading: PropTypes.bool.isRequired,
    hasErrored: PropTypes.bool,
    Outlet: PropTypes.func.isRequired,
    filters: PropTypes.arrayOf(PropTypes.string),
    filterClassName: PropTypes.string,
    filteredEvents: PropTypes.arrayOf(eventExplorePropTypes).isRequired,
    searchTerms: PropTypes.arrayOf(PropTypes.string).isRequired,
    options: PropTypes.object,
    syncFilters: PropTypes.func.isRequired,
    EmptyOutlet: PropTypes.func,
  };

  multiselectRefs = {};

  setSelectizeRef = (selectize) => {
    this.selectizeRef = selectize;
  };

  searchChanged(searchTerms, newEvents) {
    // Warning: this is a shallow copy of an object of objects.
    // Direct assignment to child objects will cause corruption.
    let newOptions = cloneDeep(defaultFilterOptions);

    if (searchTerms && searchTerms.length >= 1) {
      newEvents = filterEvents(newEvents, searchTerms);

      searchTerms.forEach((term) => {
        const type = term.split(filterDelimiter)[0];
        if (newOptions[type]) {
          const index = newOptions[type].findIndex((option) => option.returnValue === term);
          if (index > -1) {
            const newValue = Object.assign({}, newOptions[type][index], { selected: true });
            newOptions[type].splice(index, 1, newValue);
          }
        }
      });
    }
    this.props.syncFilters({
      events: newEvents,
      options: newOptions,
      searchTerms: this.selectizeRef.items,
    });
    this.syncMultiselectRefs();
  }

  searchChangedEvent = (searchTerms) => this.searchChanged(searchTerms, this.props.events);

  syncMultiselectRefs = () => {
    Object.keys(this.multiselectRefs).forEach((key) => {
      this.multiselectRefs[key].syncData();
    });
  };

  dropdownChanged(type, option, checked) {
    const dictType = dictFilters.find((filter) => filter[1] === type);
    const key = Object.entries(EventLabels[dictType[0]]).find((label) => label[1] === option.val());

    // This change gets propogated by calling Selectize's API,
    // which then triggers an onChange() event which we already listen to
    if (checked) {
      this.selectizeRef.addItem(filterReturnValue(type, key[0]));
    } else {
      this.selectizeRef.removeItem(filterReturnValue(type, key[0]));
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.events !== this.props.events) {
      this.searchChanged(nextProps.searchTerms, nextProps.events);
    }
  }

  componentDidMount() {
    if (this.selectizeRef) {
      const missingTerms = this.props.searchTerms.filter((term) => {
        if (!this.selectizeRef.items.includes(term)) {
          return term;
        }
      });
      missingTerms.forEach((term) => {
        if (term.includes('title')) {
          const newTerm = term.split('%%%%')[1];
          this.selectizeRef.createItem(newTerm, false);
        } else {
          this.selectizeRef.addItem(term);
        }
      });
    }
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className={classNames('col-xs-12', this.props.filterClassName)}>
            <EventSelectize
              handleChange={this.searchChangedEvent}
              refSelectize={this.setSelectizeRef}
              filters={this.props.filters}
            />
            <div className="events__filters">
              <span className="events__filters-title events__filter-block">Quick filter:</span>
              {this.props.filters.map((type) => (
                <MultiSelect
                  key={type}
                  buttonClass="btn btn-default events__filter events__filter-block"
                  data={this.props.options[type]}
                  buttonText={() => filterButtonLabels[type]}
                  onChange={(option, checked) => this.dropdownChanged(type, option, checked)}
                  ref={(multiselect) => {
                    this.multiselectRefs[type] = multiselect;
                  }}
                  multiple
                />
              ))}
            </div>
          </div>
        </div>
        <EventsWrapper
          isLoading={this.props.isLoading}
          hasErrored={this.props.hasErrored}
          events={this.props.filteredEvents}
          Outlet={this.props.Outlet}
          EmptyOutlet={this.props.EmptyOutlet}
        />
        <ReactTooltip
          place="left"
          type="custom"
          effect="solid"
          className="events__tooltip"
          multiline
        />
      </div>
    );
  }
}
