import React from 'react';
import moment from 'moment';
import { EventDictionary } from 'helpers/dictionaries';

const { PUB_STATUS } = EventDictionary;

const EventCallouts = (event) => {
  const privateLabel = event.private ? (
    <span className="label label-dark-blue icon-space-r event-block__callouts-label">
      <i className="fa fa-user-secret icon-space-r" />
      Private
    </span>
  ) : null;
  const draftLabel =
    event.publication_status === PUB_STATUS.DRAFT ? (
      <span className="label label-default icon-space-r event-block__callouts-label">
        <i className="fa fa-pencil-square icon-space-r" />
        Draft
      </span>
    ) : null;
  const canceledLabel = event.canceled ? (
    <span className="label label-danger icon-space-r event-block__callouts-label">
      <i className="fa fa-times icon-space-r" />
      Canceled
    </span>
  ) : null;
  const noSeatsAvailable =
    event.seats_open === 0 ? (
      <span className="label label-default icon-space-r event-block__callouts-label">
        <i className="fa fa-users icon-space-r" />
        Full Capacity
      </span>
    ) : null;
  let closingWarningType = null;
  let closingDate = null;
  if (!event.canceled) {
    const now = moment();
    const maxWarningDate = moment().add(10, 'days');
    const registrationEnd = moment(event.registration_end);
    const registrationStart = moment(event.registration_start);
    if (registrationStart.isBefore(now) && registrationEnd.isBetween(now, maxWarningDate)) {
      closingWarningType = 'Registration';
      closingDate = registrationEnd;
    }
  }
  let closingWarning = closingDate ? (
    <span className="event-block__callouts-warning icon-space-r">
      {closingWarningType} closes in{' '}
      <span className="event-block__callouts-warning--emphasized">{closingDate.toNow(true)}</span>
    </span>
  ) : null;
  const hasLabels =
    !!privateLabel || !!draftLabel || !!canceledLabel || !!closingWarning || !!noSeatsAvailable;

  return hasLabels ? (
    <React.Fragment>
      {draftLabel}
      {privateLabel}
      {canceledLabel}
      {closingWarning}
      {noSeatsAvailable}
    </React.Fragment>
  ) : null;
};

export default EventCallouts;
