import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { defaultFilterOptions, filterReturnValue, filterTypeLabels } from './helpers';
import Selectize from 'helpers/selectize';
import omit from 'lodash/omit';

const defaultProps = {
  disabled: false,
  multiple: true,
  valueField: 'returnValue',
  openOnFocus: false,
  closeAfterSelect: true,
  labelField: 'value',
  searchField: 'value',
  persist: false,
  plugins: ['remove_button'],
  placeholder: 'Search for events (example: "asl" or "workshop")',
  className: 'form-control events__search',

  create: (input) => {
    return {
      type: 'title',
      value: input,
      returnValue: filterReturnValue('title', input),
    };
  },

  render: {
    item: (item, escape) => {
      return (
        '<div>' +
        (item.type
          ? `<span class="event-search__term-type">${escape(filterTypeLabels[item.type])}:</span>`
          : '') +
        (item.value ? `<span class="event-search__term-value">${escape(item.value)}</span>` : '') +
        '</div>'
      );
    },
    option: (item, escape) => {
      return (
        '<div>' +
        (item.type
          ? `<span class="event-search__term-type">${escape(filterTypeLabels[item.type])}:</span>`
          : '') +
        (item.value ? `<span class="event-search__term-value">${escape(item.value)}</span>` : '') +
        '</div>'
      );
    },
    option_create: (data, escape) => {
      return (
        '<div class="create">' +
        `<span class="event-search__term-type">${filterTypeLabels['title']}:</span>` +
        `<span class="event-search__term-value">${escape(data.input)}</span>` +
        '</div>'
      );
    },
  },
};

const generateOptions = (filters) => {
  let options = [];
  filters.forEach((filter) => {
    const typeOptions = defaultFilterOptions[filter];
    options = options.concat(typeOptions);
  });
  return options;
};

export default class EventSelectize extends PureComponent {
  static propTypes = {
    filters: PropTypes.arrayOf(PropTypes.string),
  };

  constructor(props) {
    super(props);
    this.state = {
      options: generateOptions(props.filters),
      filters: props.filters,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.filters !== prevState.filters) {
      return {
        options: generateOptions(nextProps.filters),
      };
    }
    return null;
  }

  render() {
    const otherProps = omit(this.props, 'filters');
    return <Selectize {...defaultProps} options={this.state.options} {...otherProps} />;
  }
}
